const env = process.env;

const config = {
  apiUrl: process.env.REACT_APP_API[process.env.REACT_APP_API.length - 1] === '/'
      ? process.env.REACT_APP_API.slice(0, -1)
      : process.env.REACT_APP_API,
  guestToken: process.env.REACT_APP_GUEST_TOKEN,
  currencySiteMainId: env.REACT_APP_CURRENCY_MAIN_ID,
  lang: process.env.REACT_APP_LANG,
  catalogUrl: env.REACT_APP_CATALOG_URL,
};

// const config = {
//   apiUrl:
//     'https://api.daniparts.com/'['https://api.daniparts.com/'.length - 1] ===
//     '/'
//       ? 'https://api.daniparts.com/'.slice(0, -1)
//       : 'https://api.daniparts.com/',
//   guestToken: 'cf1a6793f669327a0417fbfed80e574f2ad8d26c',
//   currencySiteMainId: '3',
//   lang: 'UA',
//   catalogUrl: 'product-groups',
// };

export default config;
